/* Text area input */
.ra-rich-text-input .ql-editor {
  padding: 1.5rem 1.25rem !important;
  min-height: 200px;
}

/* All inputs */
.ra-input {
  margin: 0.5rem;
}

/* Fix for link tooltip - it was going out of editor*/
.ql-snow .ql-tooltip {
  left: 20px !important;
}

.MuiSvgIcon-root[data-testid="false"] {
  background-color: #cc4141;
  border-radius: 8px;
  color: white;
}

.MuiSvgIcon-root[data-testid="true"] {
  background-color: #218d21;
  border-radius: 8px;
  color: white;
}

/* Hide delete button */
.ra-delete-button {
  display: none !important;
}
